.room__image{
    width: 45%;
    object-fit: cover;
    height: 100vh;
    
}

.main__container{
    display: flex;
    flex-direction: column;
    padding: 30px;
    /* height: 100vh; */
    
}

.letters__container{
    background-color: rgba(67, 67, 67, 0.281);
    width: 60%;
    margin-left: 20%;
}

.right__container{
    height: 800px;
    margin-left: 40px;
    width: 50%;
}

.text{
    color:white;
    font-family: Copperplate;
    margin-bottom: 40px;
}

.nav__image{
    width: 120px;
    height: 120px;
    object-fit: cover;
    filter:grayscale(100%)
}

.nav__image:hover{
  filter: initial;
  cursor: pointer;
  width: 145px;
  height: 145px;
  margin-top: -11px;
  margin-left: -11px;
  border: 2px solid var(--dorado);
}


.flex__row{
    display: flex;
    flex-direction: row;
}

.image__container{
    display: flex;
    margin-bottom: 40px;
    overflow:hidden;
    justify-content: center;
    /* background-color: aqua; */
}

/*  */

  
 
  
  .mySwiper {
    width: 95%;
    height: 180px;
    /* background-color: aqua; */
    /* padding: 20px; */
    /* overflow: visible; */
  }
  
  .Swiper__Slide {
    text-align: center;
    font-size: 18px;
    /* background: #fff; */
    width: 110px !important;
    height: 110px !important;
    margin-top: 50px;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  
  .mySwiper span{
    background-color: transparent !important;
  }
  

  .Tab__image{
    object-fit: contain !important;
    height: 70vh;
    min-width: 580px;
    /* height: auto; */
    width: 95%;
    display: flex;
    margin: auto;
    
  }


  .big__image__container{
    display: flex;
    margin-bottom: 40px;
    overflow:hidden;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    /* height: 70%; */
  }

  .main__nav__conteiner{
    display: flex;
    flex-direction: column;
    /* background-color: brown; */
    height: 100vh;
  }
  .nav__imagen:hover{
    filter: initial;
    position: absolute;
    width: 150px;
    height: 150px;
    z-index: 100;
    top:-40px;
    left: -15px;
    border: 5px solid var(--dorado);
  }
   .nav__Item{
    height: 170px;
    width: 170px;
    display: flex;
    justify-content: flex-start;
    background-color: transparent !important;
    z-index: 10;
   }

   @media all and (max-width: 700px){
    .image__container{
      flex-direction: column;
      /* background-color: azure; */
    }
    .room__image{
      width: 100%;
      height: auto;
      margin-bottom: 30px;
      
    }
    .Tab__imagen{
      width: 100%;
      height: auto;
      margin: auto;
    }
    .nav__imagen{
      margin-top: -10px;
    }
    .big__image__container{
      height: auto;
      width: 100%;
      display: flex;
      margin:auto;
    }

    .right__container{
      height: auto;
    }
    
    .image__swiper,
    .main__nav__conteiner,
    .letters__container,
    .right__container{
      width: 100%;
      margin-left: 0;
    }
    
    .row{
      margin-right: 0 !important;
      margin-left: 0 !important;
      padding: 0 !important;
    }
   }