.image__conteiner{
    display: flex;
    flex-direction: row;
    width: 100%;
    /* margin-left: 5%; */
    margin-bottom: 30px;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;

}

.image{
    width: 240px;
    margin-left:20px;
    height: 300px;
    /* background-color: aliceblue; */
    transition: 2s;
    overflow: hidden;
    position: relative;
    margin-top: 20px;
}

.main__container{
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    justify-content:center;
    width: 100%;
    align-content: center;
    /* background-color: blueviolet; */
    /* height: auto; */
}

.name__conteiner{
    width: 100%;
    height: 50px;
    background-color: rgba(62, 62, 62, 0.805);
    /* position: absolute; */
    /* cambiar al insertar foto */
    /* margin-top: 300px; */
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.image:hover .name__conteiner,
.room__image:hover .name__conteiner{
    transform: translateY(-50px);
    transition: 1s;
}

.room__image:hover{
    cursor: pointer;
    filter: grayscale(100%);
    transition: 1s;
    opacity: 0.8;
}
.text{
    color:white;
    font-family: Copperplate;
    /* margin-bottom: 40px; */
    font-size: large;
    text-align: center;
}

.room__image{
    background-size: contain;
    height: 100%;
    width: 100%;
}

@media all and (max-width: 700px){
    .image__conteiner{
        flex-direction: column;
        /* background-color: aqua; */
        width: 100%;
        margin-left: 0;
        margin-bottom: 0;
    }
    .image{
        /* width: 100%; */
        height: 300px;
        margin-left: 0;
        margin-bottom: 20px;
    }
    .main__container{
        padding: 0;
        width: 100%;
    }
    .name__conteiner{
        /* display: none; */
    }
}