:root{
  --bs-border-color: #FEDB97 !important;
  
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #fff!important;
  background-color: transparent !important;
  border-color: #FEDB97 !important;
  --bs-nav-tabs-border-color: var(--bs-border-color) !important
}

.nav-tabs :hover, .nav-link, .nav-link:hover{
  color: #FEDB97 !important
}

.nav-pills .nav-link.active, .nav-pills .show .nav-link{
background-color: transparent !important;
border: 2px solid #FEDB97 !important;
border-bottom-width: 0 !important;
/* color: white !important; */
}

.accordion-button{
  background-color: transparent !important;
  color: white !important;
}

.accordion-button:focus{
  /* background-color: blue !important; */
  /* border: 2px solid green !important; */
  box-shadow: none !important;
  color: white !important;
}

.accordion{
  --bs-accordion-btn-active-icon: url('./imagenes/signoMenos.png') !important;
  --bs-accordion-btn-icon: url('./imagenes/signoMas.png') !important;
}

/* swiper */
.swiper-button-next, .swiper-rtl ,.swiper-button-prev, .swiper-pagination-fraction {
  display: none !important;
}

.swiper-pagination-bullet{
  background-color: rgb(115, 115, 115) !important;
}

.swiper-pagination-bullet-active{
  background-color: var(--bs-border-color) !important;
}