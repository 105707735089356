

.main__container{
    
    background-color: transparent;
    color: white; 
    border-bottom: 3px solid #FEDB97;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.522);
}

.container{
    margin-top: 5px;
}

.btn__social__media__container{
    display: flex;
}

.btn__social__media{
    border-radius: 50%;
    text-align: center ;
    display: flex;
    color: white !important;
    text-align: center;
    justify-content: center;
    margin-left: 15px !important;
    opacity: 1 !important;
    width: 40px;
    height: 40px;
    border: 2px solid transparent;
   
}
.button__icons{
    /* color: #FEDB97; */
    margin: auto;
    font-size: larger;
}




.btn__nav{
    color: white !important;
    margin-left: 3px;
    transition:width 0.5s ease-in-out; 
    opacity: 1 !important;

}

.line{
    height: 2px;
    width:1px;
    background-color:white;
    transition:width 0.1s ease-in-out; 
    margin-top:0 !important;
    opacity: 0.75 !important;
    
}
.btn__nav:hover{
    cursor: pointer;
    color: #FEDB97 !important; 
}
.btn__nav:hover .line{
    width: 100% !important;
    background-color: #FEDB97  !important;
    height: 2px !important;
}
.btn__social__media:hover{
    border: 2px solid #FEDB97 ;
    color: #FEDB97 !important;
}



@media all and (max-width: 700px){
    .btn__social__media__container{
        display: flex;

        flex-direction: row !important;
    }
    .container{
        z-index: 100;
    }
    .btn__nav{
        width: 80px;
    }
    .btn__nav:hover{
        cursor: pointer;
        color: #FEDB97 !important; 
    }
}




   



.image__icon__container{
    background-color: white;
    width: 52px;
    height: 52px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #FEDB97;
    transition: 1s;
}



.image__icon__container:hover,
.image__icon:hover .image__icon__container{
    background-color: #FEDB97;
    cursor: pointer;
}

.image__icon{
    width: 50px;
    height: 50px;
}