.subtitle{
    color: white !important;
    font-size: 17px;
}


.mySwiper {
    width: 100%;
    /* height: 700px; */
    /* background-color: aqua; */
    padding-top: 30px;
    
  }
  
  .swiper__slide{
    background-color: rgba(11, 11, 11, 0.818);
    padding: 10px;
    width: 500px;
    height: 350px;
    border: 3px solid var(--dorado);
    display: flex;
    justify-content: center;
   align-items: center;
   overflow: hidden;
   /* background-color: black; */
   position: relative;
   transition: 1s;

  }

  .card__container{
    display: flex;
    flex-direction: column;
    width: 380px;
    /* height: 300px; */
    overflow: hidden;
    background-color: black !important;

  }

  .header{
    width: 100%;
    height: 100px;
    position: relative;
    border-bottom: 2px solid var(--dorado);
  }

 
  .sub__card__container{
    background-color: transparent !important;
    margin-top: 20px;
  }

  .title{
    color: var(--dorado) !important;
    text-transform: capitalize;
    font-size: 18px;
  }

 
  .swiper__slide:hover{
    cursor: pointer;
   
  }


  .important_text,
  .text{
    font-size: 16px;
    color: white !important;
    text-align: start;
  }

  .text{
    font-weight:500 !important;
  }
  .important_text{
    
    font-size: 17;
    font-weight:bolder;
  }
  .backgrond__container{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url('./../../imagenes/icono\ -\ Copy.png');
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.7;

  }

  .why__us__conteiner{
    width: 60%;
    min-width: 600px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    margin-top: 50px;
    background-color: rgba(80, 81, 82, 0.619);
    border: 20px solid black;
    outline: 2px solid var(--dorado);
    margin-bottom: 20px;
  }

  @media all and (max-width: 700px){
    .why__us__conteiner{
      min-width: 100%;
      padding: 5px;
    }

    .sub__card__container{
      background-color: transparent !important;
    }

    .important_text{
    
      font-size: 18;
      font-weight:initial;
    }

   
  }