/* td {
    padding: 0px 10px;
}

table{
    margin-top: 7%;
    margin-left: 2%;
    margin-bottom: 50px;
    border-width: 1px;
    border-style: solid;
    border-color: white;
    color: white;
} */

.contact__us__container{
    margin-bottom: 100px;
    min-width: 100vh;
}

.sub__contact__us__container{
    width: 750px;
    margin-left: auto;
    margin-right: auto;
}

.forms,
.forms__message{
    width: 750px;
    display: flex;
    margin-left: auto;
    margin-right: auto;
    border-width: 2px;
    border-color: black;
}

.forms__message{
    height: 200px;
}

.forms__label{
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    text-align: start;
    text-transform: uppercase;
    color: #FEDB97;
    
}



/* .formsDiv{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 750px;
    justify-content: space-between;
} */

/* .formsTimeDiv{
    display: flex;
    flex-direction: row;
    column-gap: 10px;
} */

/* .formsItem{
    width: 200px;
    height: 40px;
    border-width: 2px;
    border-color: black;
} */

/* .formsSubDiv{
    height: 40px;
} */
/* 
.formsItem1{
    width: 80%;
    height: 40px;
    margin-left: 0 ;
    border-width: 2px;
    border-color: black;
    border-radius: 10px;

} */

.alert{
    margin-top: 30px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    background-color: rgba(238, 248, 208, 0.977);
}

.alert__Text{
    font-size: 17px;
}

.button{
    width: 150px;
}

.title{
    text-transform: uppercase;
    color: #FEDB97;
}

.btn__conteiner{
    display: flex;
    margin-top: 10px;
}


@media all and (max-width: 750px){
    .contact__us__container{
        min-width:95% !important;
        /* background-color: rgb(222, 137, 34); */
    }

    .sub__contact__us__container{
        margin-left: auto;
        width:95% !important;
        margin-right: auto;
    }

    .sub__contact__us__container, 
    .forms, 
    .forms__message{
        width:100% !important;
    }
    
    /* .formsDiv{
        flex-direction: column !important;
    }
    .formsItem1{
        margin-left: 0;
    }

    .formsSubDiv{
        margin-bottom: 20px;
    }
    .formsText1{
        margin-left: -130px;
    } */

    .title{
        padding-top: 30px;
    }

    .btn__conteiner{
        margin-left: auto;
        margin-right: auto;
        margin-top: 50px;
        padding-bottom: 50px;
    }

    .button__submit{
        margin-left: auto;
        margin-right: auto;
        width: 150px;
        font-size: 1.5rem !important;
    }
    .contact__us__container{
        margin-bottom: -20px !important;
    }

    .forms__label{
        width:100% !important;
    }
}