.main__container{
    width:100%;
    /* background-color: aqua; */
    text-align: center;
    display: flex;
    flex-direction: column;
}

.title{
    text-transform: uppercase;
    color: #FEDB97;
    font-family: Georgia, serif;
    font-size: medium;
    margin-top: 15px;

}

.subtitle{
    color: white;
    text-transform: uppercase;
    font-family: Georgia, serif;
    font-size:large;
    margin-bottom: 30px;
}

/* .imagenesCont{
    display: flex;
    flex-direction: row;
    width: 90%;
    margin-left: 5%;
    margin-bottom: 30px;
} */

/* .imagenes{
    width: 32%;
    margin-left: 20px;

    height: 300px;
    background-color: aliceblue;
} */

.btn__container{
    margin-bottom: 40px;
}

.image{
    width: 300px;
  height: 300px;
  object-fit:cover;

}

.image__container{
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    width: 850px;
    /* height: 720px; */
    overflow: hidden;
    padding: 20px;
    /* background-color: silver; */
    margin-left: auto;
    margin-right: auto;
}
.sub__image__container{
    /* flex: 0 0 50px; */
    margin-right: 50px;
    margin-top: 25px;
    height: 300px;
    width: 220px;
    background-color: rgb(251, 255, 254);
    position: relative;
    transition: 2s;
    overflow: hidden;

}

.image__text{
    color:var(--dorado);
    font-family: Copperplate;
    margin-bottom: 40px;
    font-size: medium;
}

.text__container{
    background-color: rgba(29, 29, 29, 0.755);
    text-align: start;
    padding: 2px 10px ;
    height: 30px;
    width: 100%;
    position: absolute;
    transition: 2s;

    
}

.image:hover{
    cursor: pointer;
    filter:grayscale(100%);
    transition: 2s;
    opacity: 0.7;
}
.sub__image__container:hover .text__container{
    transform: translateY(-30px);
    /* background-color: antiquewhite; */
}

@media all and (max-width: 700px){
    .image__container{
        width: 90%;
        /* background-color: aqua; */
        justify-content: center;
        height: 100%;
    }
    .sub__image__container{
        margin-right: 0;
    }
    /* .imagenesCont{
        flex-direction: column;
    }
    .imagenes{
        width: 90%;
        margin-bottom: 20px;
    } */
}