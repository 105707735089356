.main__conteiner{
    width: 100%;
    height: auto;
    min-height: 600px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    
    /* padding: 10px; */
    padding-right: 10px;
    position: relative;
    /* overflow-y: auto; */
    margin-bottom: 100px;
    /* background-color: aqua; */
    overflow: hidden;
    margin-top: 50px;
    /* padding-bottom: -10px; */
    
}
.background {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-image: url('./../../imagenes/bath1/IMG_7921\ -\ Copy.jpg');  */
    background-size: cover; 
    border-bottom: 2px solid var(--dorado);
    background-attachment: fixed; /* Fijar la imagen de fondo */
    background-position: bottom;
    
  }

  
  .letters{
    /* background-color: aqua; */
    z-index: 1;
    width: 50%;
    min-width: 500px;
    height: 200px;
    display: flex;
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 50px;
  }

  .image__conteiner{
    position: absolute;
    z-index: 1;
    height: 340px;
    bottom: -10%;
    right: 0;
    padding: 20px;
    border: 2px solid black;
    border-top-color: var(--dorado);
    border-left-color: var(--dorado);
    border-right-color: var(--dorado);
    background-color: black;
    border-radius: 50%;
  }

  .image{
    height: 300px;
    width: 300px;
    border-radius: 50%;
    /* position: relative; */
    position: relative;
    z-index: 1;
    margin-top: auto;
    margin-bottom: -40px;
    margin-left: auto;
    border: 20px solid black;
    outline: 2px solid var(--dorado);
  }


  @media all and (max-width: 700px){
    .letters{
      margin-top: 30px;
      min-width: 90% !important;
      height: auto;
      background-color: rgba(30, 29, 29, 0.568);
      margin-left: 5%;
    }

    .image{
      width: 90%;
      margin-left: 5%;
      /* margin-right: 5%; */
    }

    .main__conteiner{
      padding: 0;
    }
  }