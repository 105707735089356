.main__Container{
    background-color: rgba(114, 114, 114, 0.778);
    padding: 40px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
}
/* 
.contText{
    width: 20%;
} */

.text{
    color:white;
    font-size: small;
    width: 20%;
    min-width: 230px;
    margin-left: 10px;
    margin-top: 20px;
}

.btn__container{
    width: 25%;
    min-width: 300px;
    margin-left: 20px;
    margin-top: 20px;
    text-align: center;
    /* background-color: aqua; */
}
.Sub__Btn__Container,
.sub__btn__container{
    display: flex;
    text-align: center !important;
    justify-content: center;
}

.Sub__Btn__Container{
    justify-content: start !important;
}

.btn__social__media{
    width: 45px;
    height: 45px;
    font-size:large;
    justify-content: center;
    margin-left: 10px;
    border-radius: 50%;
    border: 2px solid #FEDB97 ;
    color:#FEDB97;
    margin-bottom: 19px;
    background-color: transparent;
    transition: 0.8s;
    display: flex;
}

.button__icon{
    /* color: #FEDB97; */
    margin: auto;
    font-size: larger;
}


.btn__contact,
.btn__navigation{
    background-color: #FEDB97 !important;
    border: 3px solid #FEDB97 !important;
    transition: 0.8s !important;
    color:black !important;
    font-family: Georgia, serif !important;

}

.btn__navigation{
    margin-left: 15px;
    margin-bottom: 7px;
}

.btn__contact:hover,
.btn__navigation:hover{
    background-color: transparent !important;
    color: #FEDB97 !important;
    border: 3px solid #FEDB97;

}

.contact__us__container{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin: 10px;
}

.text__contact{
    color:white;
    font-size: large;
}

.btn__social__media:hover{
    background-color: #FEDB97;
    color: white;
    border: 3px solid #FEDB97;
}

.icons{
    color: #FEDB97;
    font-size: x-large;
    margin-right: 10px;
}

.phone_number{
    padding-left: 5px;
}

.image__icon__container{
    background-color: white;
    width: 113px;
    height: 112px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #FEDB97;
    margin-right: 40px;
    transition: 1s;
}

.image__icon__container:hover,
.image__icon:hover .image__icon__container{
    background-color: #FEDB97;
    cursor: pointer;
}

.image__icon{
    width: 110px;
    height: 110px;
}

@media all and (max-width: 700px){
    .main__Container{
        flex-direction: column;
        padding: 12px;
        margin-top: 20px;
    }

    .contText,.text, .btn__container{
        width: 100%;
        margin-left: 0;
    }

    .image__icon__container{
        /* width: max-content; */
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 15px;
    }

    .text{
        font-size: 1.2rem;
        padding-left: 20px;
        padding-right: 20px;
        text-align: justify;
    }

    .Sub__Btn__Container{
        flex-direction: column;
        width: 31%;

        margin-left: 32%;

    }
    .btn__container__contact{
        margin-bottom: 8px;
        width: 100%;
        justify-content: start;
        
    }

    .btn__navigation{
        width: 100% !important;
    }
    .btn__contact{
        width: 31%;
        margin-left: 10px !important;
    }
    .text__contact{
        display: flex;
        flex-direction: row;
    }

    .btn__container{
        margin-top: 0;
    }
    .contact__us__container{
        width: max-content;
        margin-left: auto;
        margin-right: auto;
    }
}