:root {
    --transition-duration: 0.5s;
    --transition-delay: 0;
    --bigWidth: 90%;
}

.main__conteiner{
  /* background-color: rgba(0, 0, 0, 0.522); */
}

.regular__conteiner{
    /* background-color: aqua; */
    background-image: url('./../../imagenes/bath1/IMG_7921\ -\ Copy.jpg');
    /* background-size: contain; */
    background-repeat: no-repeat;
    background-size: cover;
    /* background-color: rgba(0, 0, 0, 0.522); */

    width: var(--bigWidth);
    min-height: 700px;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    /* padding: 30px; */
}

.left__image{
  /* width: calc(--bigWidth * 2.5); */
  /* zoom: 1.8; */
  min-height: 776px;
  height: 100vh;
  object-fit: cover;
  background-size: cover;
  transition: 300ms cubic-bezier(0.1, 0.7, 0.6, 0.9);
  transition-timing-function:ease-in-out;
  
}


.left__conteiner{
    background-color: rgb(85, 85, 86);
    /* background-image: url('./../../imagenes/I1.jpg'); */
    width: 50%;
    min-height: 700px;
    height: 100vh;
    /* transition: width 1s; */
    transition: 300ms cubic-bezier(0.1, 0.7, 0.6, 0.9);
    transition-timing-function:ease-in-out;
    border: 2px solid black;
    overflow: hidden !important;
  
}
.right__conteiner{
    background-color: transparent;
    /* background-image: url('./../../imagenes/I2.jpg'); */
    width: 50%;
    height: 700px;
    /* /* transition: width 1s; */
    transition: 300ms cubic-bezier(0.1, 0.7, 0.6, 0.9); 
    transition-timing-function: ease-in-out;
    border: 2px solid black;
    overflow: hidden;
}

.right__conteiner:hover {
    width: 10000000px;
    cursor: pointer;
}



.left__conteiner:hover{
    width: 1000000px;
    cursor: pointer;
}


.letters{
    z-index:1;
    background-color: transparent;
    position: absolute;
    top:200px;
    left:10%;
    width: 26%;
    /* text-align: center; */
    
}

.title{
    text-transform: uppercase;
    color: #FEDB97;
}

.text{
    color:white;
    font-family: Copperplate;
    margin-bottom: 40px;
}

.Boton{
    display: flex;
    margin-left: auto;
    margin-right: auto;
    height: 45px;
    background-color: transparent !important;
    width: 105px;
    color: white !important;
    /* text-align: center; */
    border: 0px solid transparent !important;
}

.Boton:hover{
    background-color: transparent !important;
    color: #FEDB97 !important;

}

/* animacion boton */
.borderTop,
.borderBottom {
    width: 100%;
    height: 1px;
    /* background-color: aliceblue; */
    transform: scaleX(0);
  }

 .borderLeft,
 .borderRight {
    width: 1px;
    height: 100%;
    transform: scaleY(0);
  }
  .borderTop,
  .borderLeft {
    top: 0;
    /* background-color: blue !important; */
  }
  .borderBottom{
    bottom: 0;
    transform-origin: bottom right;

  }

    .borderTop {
    transform-origin: top left;
  }
  .borderLeft {
    transform-origin: bottom left;
  }
  .borderRight {
    left: auto;
    right: 0;
    transform-origin: top right;
    
  }

 
  .borderRight{
    position: absolute;
    right: 0;
    top: 0;
    background: currentColor;
    transition: transform var(--transition-duration) ease-in-out;
  }

  .Boton,
  .borderLeft,
  .borderTop,
  .borderBottom{
    position: absolute;
    left: 0;
    background: currentColor;
    transition: transform var(--transition-duration) ease-in-out;
  }
  .Boton:hover .borderTop,
  .Boton:hover .borderBottom {
    transform: scaleX(1);
  }
  .Boton:hover .borderLeft,
  .Boton:hover .borderRight {
    transform: scaleY(1);
  }
  .Boton:hover .borderRight {
    transition-delay: var(--transition-delay);
  }
  .Boton:hover .borderBottom {
    transition-delay: calc(var(--transition-delay) * 2);
  }
  .Boton:hover .borderLeft {
    transition-delay: calc(var(--transition-delay) * 3);
  }

  .Boton[data-animation="diagonal"] .borderLeft {
    transform-origin:top left;
    /* background-color: aliceblue !important; */
  }
  .Boton[data-animation="diagonal"] .borderRight,
  .Boton[data-animation="diagonal"] .borderBottom {
    transform-origin: bottom right;
  }
  .Boton[data-animation="diagonal"] .Boton:hover [id=border]{
    transition-delay: 0s;
  }
  .movil__view__conteiner{
    display: none;
  }
  @media all and (max-width: 700px){
    .regular__conteiner{
      display: none;
    }
    .movil__view__conteiner{
      display: flex;
      flex-direction: column;
      /* margin-top: 30px; */
    }

    .letters{
      width: 86%;
      background-color: rgba(100, 100, 100, 0.085);
      padding: 5px;
      position: relative;
      text-align: center;
      /* justify-content: ; */
      /* height: 240px; */
      top: 20px;
      left:6%
    }
    .text{
      font-size: 20px;
    }
   .Boton{
    position: relative;
   }
    
    
  }