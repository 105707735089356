.letters{
    z-index:1;
    background-color: transparent;
    align-items: center;
    /* background-color: aqua; */
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    
}

.title{
    text-transform: uppercase;
    color: #FEDB97;
}

.text{
    color:white;
    font-family: Copperplate;
    margin-bottom: 40px;
}


.questions__container{
    display: flex;
    /* background-color: aqua; */
    /* height: 100%; */

}
.accordion__container{
    width: 97%;
    /* background-color: aqua !important; */
}

.questions{
    /* background-color: blue; */
    width: 80%;
    margin-left: 10%;

}


.accordion__Item{
    background-color: transparent !important;
    color: white !important;
}

.accordion__Item__Header{
    background-color: transparent !important;
    
}