.body{
    background-image: url('./../../imagenes/marmol-negro-textura-fondo-textura-marmol-tailandia-marmol-natural-abstracto-blanco-negro-diseno.jpg');
    background-repeat: repeat;
    background-size: contain;
    overflow: hidden;
    
}

.conteiner{
    background-color: rgba(0, 0, 0, 0.522);
    padding: 50px;
}

@media all and (max-width: 700px){
    .conteiner{
        padding: 10px;
    }

}