/* .contenedorImagen{
    margin-top: 5%;
    /* background-color: beige; */
    /* overflow: hidden !important; 
} */

/* bien */
.carousel {
    /* background-color: #FEDB97 !important; */
    color:#FEDB97 !important;
    /* opacity: 0 !important; */
}
.contenedorPrincipal{
    width: 100%;
    height: 100%;
    display: flex;
    /* background-color: aquamarine; */
    /* background-color: black; */
    background-color: transparent;
    margin-top: -50px !important;
    overflow: hidden !important;
 
    margin:auto;
    flex-direction: column;
    text-align: center;
}

/* bien */
.Carousel__Container{
    position: relative;
    margin-top: 40px;
    margin-bottom: 45px;
    height: 100%;
    /* width: 100%; */
    background-size: cover;
    /* background-color: rgba(56, 56, 56, 0.658); */
    /* background-color: blue; */
    background-color: transparent;
    text-align: center;
    overflow: hidden !important;
}

/* bien */
.carousel{
    background-color: transparent;
    width: 100%;
    height: 700px;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden !important;
    text-align: center;
    transition-duration: 1s;
    position: relative;
}

.carousel__item__container{
    width: 100%;
    /* background-color: aqua; */
    height: 700px;
    /* text-align: center; */
    margin-bottom: 50px;
    /* overflow: hidden !important; */
    /* margin-left: auto;
    margin-right: auto; */
    background-color: transparent;
    
    
}

.carousel__item{
    position: relative;
    width: 100%;
    height: 100%;
    /* margin-left: auto;
    overflow: hidden !important;
    margin-right: auto; */

    background-color: #525252;
}

.image__contain{
    position: absolute;
    overflow: hidden;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    /* z-index: 100; */
}


.image{
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    object-fit: cover;
    animation: zoom 30s infinite;
    
}

.text__container{
    z-index: 10;
    width: 75%;
    position: absolute;
    top:30%;
    left: 12.5%;
   

}

.title{
    font-size: 18px;
}

.subtitle{
    font-size: 20px;

}

.letters{
    background-color: rgba(42, 41, 41, 0.644);
}

@keyframes zoom {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
        transform: scale(1);
      }
    
  }
.comentariosDiv{
    /* background-color: aqua; */
}

@media all and (max-width: 700px){
    .contenedorPrincipal{
        width:100%;
        /* margin-bottom: 30px; */
        margin-left: auto;
        margin-right: auto;
        margin-top: 5px !important;
    }
    .contenedorCarousel__Container{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
    .image__contain{
        
        top: -15px;
       
    }
    
    
    .carousel{
        height: 400px;
        margin-left: auto;
        margin-right: auto;
    }
    
    .image{
        margin: 10px;
        width: 500px;
        height: 400px;
        /* background-size: contain !important; */
        /* zoom: 0.3; */
        margin-left: auto;
        margin-right: auto;

    }

    .comentariosDiv{
        width: 95%;
        margin-left: auto;
        margin-right: auto;
    }

    .title{
        font-size: 12px;
    }
    
    .subtitle{
        font-size: 14px;
        /* text-transform:none; */
    
    }
}
