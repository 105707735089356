:root{
    --bs-nav-link-color: white !important;
}

.main__conteiner{
    width: 109%;
    margin-left: -4.5%;
    margin-top: 0;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.navegador{
    /* background-color: aqua; */
    text-align: center;
    justify-content: center;

}

.nav__item{
    border-radius: 0px !important;
    /* background-color: aquamarine; */
}

.container{
    /* background-color: brown; */
    text-align: center;
    justify-content: center;
    width: 400px;
}

.nav__contenier{
    width: 60%;
    height: 100%;
    justify-content: center;
    display: flex;
    flex-direction: column;
    /* background-color: aqua; */
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
}

.nav{
    text-align: center;
    justify-content: center;
    margin-bottom: 30px;
    /* margin-left: 30px !important; */
    /* background-color: aqua !important; */
    /* width: 700px; */
}
.flex__row{
    /* background-color: aquamarine; */
    justify-content: center;
    border-bottom: 2px solid #FEDB97;
}

.nav{
    color: blanchedalmond !important;
}
.button__iconos{
    display: none;
}

@media all and (max-width: 700px){
    .flex__row{
        margin-bottom: 20px;
    }
    .buton__nav{
        display: none;
    }
    .button__iconos{
        display: block;
        font-size: larger;
        color: #FEDB97;
    }
    
}

.tab__item{
    display: flex;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    
    justify-content:center;
    
    
}


.Item{
    /* height: 300px; */
    /* width: 320px; */
    position: relative;
    display: flex;
    justify-content:center;
}